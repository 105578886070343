import { createSlice } from '@reduxjs/toolkit';

const limit= 768;
const sizePositionIsSmallCompute = ( size ) => size < limit ? true : false;

const initialState = {
  position: 0,
  sizePositionIsSmall: sizePositionIsSmallCompute( window.innerWidth ),
};

const sizeSmallSlice = createSlice({
  name: 'sizeSmall',
  initialState,
  reducers: {
    updateSizePositionIsSmall: ( state, action ) => {
      const [data, debug] = action.payload;
      state.position = data;
      state.sizePositionIsSmall = sizePositionIsSmallCompute( state.position );
      debug && console.log('updateSizePositionIsSmall:', state.position, ' / ', limit, ' => ', state.sizePositionIsSmall );
    },
  },
});

export const { updateSizePositionIsSmall } = sizeSmallSlice.actions;

export const selectSizePositionIsSmall = (state) => state.sizeSmall.sizePositionIsSmall;

export default sizeSmallSlice;
