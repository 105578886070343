import React from "react";
import { Link } from "react-scroll";

const LinkAction = props => {

    const linkClickedHandler = () => {
        props.toggleLegalPlaceHolder && props.toggleLegalPlaceHolder();
    };

    return (

        <li className=" 
            cursor-pointer text-2xl text-light-cyan underline
            hover:text-3xl 
            "
        >
            <Link
                onClick={ linkClickedHandler }
                smooth spy to={ props.toggleLegalPlaceHolder ? "header" : "footer" }
            >   
                <span className="select-none" >{props.label}</span>
            </Link>
        </li>
    );
}

export default LinkAction;