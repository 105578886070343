import React from 'react';


const MailTo = props => {

  return (
    <a href={`mailto:${props.children}`} className='underline text-honolulu-blue' >{props.children}</a>
  )
}

export default MailTo;