import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLegalsVisible } from '../../Slices/contentSlice';

const ImgA = props => {

    const isLegalsVisible = useSelector( selectIsLegalsVisible );

    const firsLinkStyle = isLegalsVisible ? "text-federal-blue underlink" : "text-marian-blue"

    let image_path = '';
    try {
        image_path = require('../../images/' + props.image);
    } catch (err) {
        image_path = require('../../images/Inf_00.png');  //set default image path
    }
    
    return (
        <>
            <img
                className='h-auto md:rounded-ss-[64px] md:rounded-ee-[64px]' 
                src={image_path}
                alt={props.title}
            />

            <a 
                className={`font-extra-light italic ${ firsLinkStyle }`}
                href={props.url_link}
                target='_blank'
                rel='noopener noreferrer'
            >
                {props.text_link}
            </a>

            { props.url_link_author &&
                <a className='font-extra-light italic text-federal-blue' href={props.url_link_author} target='_blank' rel='noopener noreferrer' >( Image de {props.text_link_author} )</a>
            }
        </>

    );
}


export default ImgA;