import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisibleBarSmall: false,
};

const navBarSmallSlice = createSlice({
  name: 'navBarSmall',
  initialState,
  reducers: {
    toggleIsVisibleBarSmall: ( state, action ) => {
      const [debug] = action.payload ?? [ false ];
      state.isVisibleBarSmall = !state.isVisibleBarSmall;
      debug && console.log('toggleIsVisibleBarSmall');
    },
    updateIsVisibleBarSmall: ( state, action) => {
      const [isVisible, debug] = action.payload;
      state.isVisibleBarSmall = isVisible;
      debug && console.log('updateIsVisibleBarSmall => ', state.isVisibleBarSmall );
    },
  },
});

export const { toggleIsVisibleBarSmall, updateIsVisibleBarSmall } = navBarSmallSlice.actions;

export const selectIsVisibleBarSmall = (state) => state.navBarSmall.isVisibleBarSmall;

export default navBarSmallSlice;
