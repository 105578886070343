import React from "react";
import { useSelector } from 'react-redux';
import { selectIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';
import { selectIsLegalsVisible } from "../../Slices/contentSlice";

import Title1 from "../Common/Title1";
import HeaderCollection from './HeaderCollection'

const Header = () => {

    const isVisibleBarSmall = useSelector( selectIsVisibleBarSmall );
    const isLegalsVisible = useSelector( selectIsLegalsVisible );

    return (
        <header 
            id="header"
            className="
                Cafe p-4 h-auto bg-black bg-fixed
                md:h-auto w-auto
        ">
            <HeaderCollection />

            { !isVisibleBarSmall &&
                <div className="h-68 w-full md:h-88" >
                <Title1>{ isLegalsVisible ? "Mentions légales" : "Simple & Concret"}</Title1>
                </div>
            }

            <div className="w-full text-right">
                <a 
                    className='font-extra-light italic text-light-cyan' 
                    href='https://fr.freepik.com/photos-gratuite/concept-collage-html-css-personne_36295461.htm#&position=7&from_view=collections&uuid=c2ec1728-155f-4974-a30d-f16d2dbce7e2'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Image de Freepik
                </a>
            </div>

        </header>
        );
    }
    
export default Header;