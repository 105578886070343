import { configureStore } from '@reduxjs/toolkit';
import scrollHeaderSlice from '../Slices/scrollHeaderSlice';
import sizeSmallSlice from '../Slices/sizeSmallSlice';
import navBarSmallSlice from '../Slices/navBarSmallSlice';
import contentSlice from '../Slices/contentSlice';
import activeNavActionSlice from '../Slices/activeNavActionSlice';
import sectionsSlice from '../Slices/sectionsSlice';

export default configureStore({
  reducer: {
    scrollHeader: scrollHeaderSlice.reducer,
    sizeSmall: sizeSmallSlice.reducer,
    navBarSmall: navBarSmallSlice.reducer,
    content: contentSlice.reducer,
    activeNavAction: activeNavActionSlice.reducer,
    sections: sectionsSlice.reducer,
  },
  devTools: ( process.env.NODE_ENV !== 'development' ? false : true ),
})