import React from "react";
import { useSelector } from 'react-redux';
import { selectSizePositionIsSmall } from '../../Slices/sizeSmallSlice';

import NavAction from './NavAction';

const NavBarMedium = () => {

    const sizeSmall = useSelector( selectSizePositionIsSmall );

    return (
        <>
            { !sizeSmall && 
                <ul 
                    className={` 
                        hidden
                        md:flex md:flex-row md:w-auto md:justify-between md:m-0 md:px-2 md:py-1
                        `}
                >
                    <NavAction label="Présentation" to="presentation" />
                    <NavAction label="Missions" to="missions" />
                    <NavAction label="Valeurs" to="valeurs" />
                    <NavAction label="Fondateur" to="fondateur" />
                    <NavAction label="Produits" to="produits" />
                    <NavAction label="Services" to="services" />
                    <NavAction label="Contact" to="contact" />
                </ul>
            }
        </>
    );
};

export default NavBarMedium;