import React, { Fragment } from 'react';
import NewlineText from './NewlineText';
import MailTo from './MailTo';
import UrlLink from './UrlLink';

const Table = props => {

    return (

        <div className="grid grid-cols-3 grid-flow-row gap-4">

            {
                props.table && props.table.map((elt, index) => {
                    if (elt.key) {
                        return (
                            <Fragment key={`table-${index}`} >
                                <div className="font-bold col-span-1">
                                    {elt.key && `${elt.key}:`}
                                </div>
                                <div className="col-span-2">
                                    <>
                                        { elt.text && <NewlineText >{elt.text}</NewlineText >}
                                        { elt.mailTo && <MailTo >{elt.mailTo}</MailTo >}
                                        { elt.url_link && <UrlLink href={`${elt.url_link}`} >{elt.text_link}</UrlLink>}
                                    </>
                                </div>
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment key={`table-${index}`} >
                                <div className="col-span-3 text-center">
                                    <>
                                        { elt.text && <NewlineText >{elt.text}</NewlineText >}
                                        { elt.mailTo && <MailTo >{elt.mailTo}</MailTo >}
                                        { elt.url_link && <UrlLink href={`${elt.url_link}`} >{elt.text_link}</UrlLink>}
                                    </>
                                </div>
                            </Fragment>
                        );
                    };
                })
            }
        </div>
    );
};

export default Table;