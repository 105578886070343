const sectionsContent = [
    {
        "id": "presentation",
        "title": "En quelques mots",
        "elements": [
            {
                "list": [
                    {
                        "text": "Keep2C, fondée en 2024 est une société spécialisée dans l’édition de logiciel pour les petites et moyennes structures."
                    },
                    {
                        "text": "La société ne se revendique pas comme une société de service. En effet, ses conceptions s’orientent principalement vers des produits qui pourront être proposés à d’autres structures intéressées."
                    },
                    {
                        "text": "Keep2C offre également des services complémentaires tels que de la formation, de la maintenance et du déploiement."
                    },
                    {
                        "text": "Concevoir des solutions, conseiller et organiser font aussi partie des compétences de l’entreprise."
                    },
                ],
            },
            {
                "image": {
                    "file": "_presentation.png",
                    "alt": "Homme utilisant la technologie intelligente de maquette psd de tablette numérique.",
                    "url_link": "https://fr.freepik.com/photos-gratuite/homme-utilisant-technologie-intelligente-maquette-psd-tablette-numerique_16251062.htm#fromView=search&page=1&position=50&uuid=ec38bea9-441c-477c-a8a1-512b0d244982",
                    "text_link": "Image de rawpixel.com sur Freepik",
                },
            },
        ],
    },
    {
        "id": "missions",
        "title": "Missions",
        "bg_img": "Fond",
        "elements": [
            {
                "image": {
                    "file": "_missions.png",
                    "alt": "Main utilisant le remix numérique de technologie avancée d'écran virtuel",
                    "url_link": "https://fr.freepik.com/photos-gratuite/main-utilisant-remix-numerique-technologie-avancee-ecran-virtuel_17122944.htm#fromView=search&page=2&position=19&uuid=d4e05dfd-8f05-44e1-8f5f-fbb9ed3c290b",
                    "text_link": "Image de rawpixel.com sur Freepik",
                },
            },
            {
                "list": [
                    {
                        "text": "Permettre aux petites et moyennes structures d’accéder à des logiciels adaptés à leur taille et à leurs besoins."
                    },
                    {
                        "text": "Compléter l’offre de création de logiciels en proposant des services annexes afin de ne pas multiplier les prestataires externes."
                    },
                    {
                        "text": "Prioriser la fabrication, la conception et la qualité afin d'enrichir l'offre sur \"étagère\" proposée par Keep2C."
                    },
                ],
            },
        ],
    },
    {
        "id": "valeurs",
        "title": "Valeurs",
        "elements": [
            {
                "list": [
                    {
                        "text": "Keep2C a pour souhait de devenir une vraie offre crédible en proposant des solutions raisonnables fondées avant tout sur le bon sens."
                    },
                    {
                        "text": "Les relations entre clients et partenaires de Keep2C doivent être fondées sur un principe \"gagnant-gagnant\"."
                    },
                    {
                        "text": "Keep2C privilégie les visions basées sur le long terme."
                    },
                ],
            },
            {
                "image": {
                    "file": "_valeurs.png",
                    "alt": "La technologie, mains, une entente.",
                    "url_link": "https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4256272",
                    "text_link": "Pixabay",
                    "url_link_author": "https://pixabay.com/fr/users/kiquebg-5133331/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4256272",
                    "text_link_author": "kiquebg",
                },
            },
        ],
    },
    {
        "id": "fondateur",
        "title": "Fondateur",
        "bg_img": "Fond",
        "elements": [
            {
                "image": {
                    "file": "_fondateur.png",
                    "alt": "Scène avec une personne d'affaires travaillant dans un bureau futuriste",
                    "url_link": "https://fr.freepik.com/photos-gratuite/scene-personne-affaires-travaillant-dans-bureau-futuriste_94952433.htm#fromView=search&page=1&position=21&uuid=eed1e255-0a57-4bb4-90d9-f873e6be6616",
                    "text_link": "Image de freepik",
                },
            },
            {
                "list": [
                    {
                        "text": "Depuis 2001, Frédéric CHARRON se consacre aux métiers de l’informatique."
                    },
                    {
                        "text": "En 2024, il cumule 22 ans d’expérience dans le domaine du développement de logiciels, 10 ans dans le domaine la maintenance de postes, serveurs et réseaux sur un parc dépassant les 2800 machines et 4 ans en tant que formateur bureautique."
                    },
                    {
                        "text": "Diplômé en tant qu’Analyste Informaticien de l’A.F.C.E.P.F. (formation homologuée de niveau II), il complète et améliore en permanence ses connaissances en autodidacte."
                    },
                    {
                        "text": "Après de nombreuses expériences en entreprise, en 2024, il décide de fonder Keep2C afin de réaliser des logiciels de qualité, pratiques et répondant à des exigences opérationnelles et concrètes."
                    },
                ],
            },
        ],
    },
    {
        "id": "produits",
        "title": "Produits",
        "elements": [
            {
                "list": [
                    {
                        "text": "Dans les petites et moyennes structures, on ne peut pas toujours faire appel à des SSII pour construire des logiciels de petite taille et la solution consiste souvent à se réfugier sur des outils bureautiques ou à s'adapter à des outils choisis par dépit."
                    },
                    {
                        "text": "Keep2C vous propose de construire des logiciels simples basés sur des outils open source et peu couteux."
                    },
                    {
                        "text": "Tout logiciel construit pourra faire l'objet d'un partenariat ou d'un accord s'il rejoint les produits \"sur étagère\" proposés par Keep2C."
                    },
                ],
            },
            {
                "image": {
                    "file": "_produits.png",
                    "alt": "Concept de collage html et css avec personne",
                    "url_link": "https://fr.freepik.com/photos-gratuite/concept-collage-html-css-personne_36295468.htm#&position=14&from_view=collections&uuid=0d46d50e-956d-438b-94d4-c370bcc13b53",
                    "text_link": "Image de freepik",
                },
            },
        ],
    },
    {
        "id": "services",
        "title": "Services",
        "bg_img": "Fond",
        "elements": [
            {
                "image": {
                    "file": "_conseil.png",
                    "alt": " Jeu d'échecs, jeu, stratégie.",
                    "url_link": "https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3325010",
                    "text_link": "Pixabay",
                    "url_link_author": "https://pixabay.com/fr/users/jeshoots-com-264599/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3325010",
                    "text_link_author": "Jan Vašek",
                },
            },
            {
                "list": [
                    {
                        "bold": "CONSEIL:"
                    },
                    {
                        "text": "Profitez de l'expérience de Keep2C dans vos choix stratégiques pour votre système d'information."
                    },
                    {
                        "text": "Keep2C vous aide à choisir vos logiciels et vous conseille sur votre infrastructure."
                    },
                    {
                        "text": "Keep2C vous conseille également en matière de sécurisation de vos données."
                    },
                ],
            },
            {
                "list": [
                    {
                        "bold": "MAINTENANCE / DEPLOIEMENT:"
                    },
                    {
                        "text": "Keep2C propose des prestations dans les secteurs de la maintenance."
                    },
                    {
                        "text": "La société peut intervenir dans le cadre de dépannage ou de déploiement logiciel sur poste ou sur serveur."
                    },
                    {
                        "text": "Vous avez un besoin ponctuel d'un informaticien, Keep2C peut vous proposer un partenariat."
                    }
                ],
            },
            {
                "image": {
                    "file": "_maintenance.png",
                    "alt": "Centre d'appels vide avec des ordinateurs.",
                    "url_link": "https://fr.freepik.com/photos-gratuite/personne-dans-poste-travail-vide-du-centre-appels-ordinateurs-instruments-audio-aucune-personne-aux-bureaux-du-service-client-technologie-telecommunication-offrant-assistance-telephonique_27782339.htm#fromView=search&page=1&position=18&uuid=bc5cc6f5-67f0-4f53-a51e-5015df7bfc1f",
                    "text_link": "Image de DC Studio sur Freepik",
                },
            },
            {
                "image": {
                    "file": "_formations.png",
                    "alt": "Marque, Marqueur, Main.",
                    "url_link": "https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=516278",
                    "text_link": "Pixabay",
                    "url_link_author": "https://pixabay.com/fr/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=516278",
                    "text_link_author": "Gerd Altmann",
                },
            },
            {
                "list": [
                    {
                        "bold": "FORMATIONS:"
                    },
                    {
                        "text": "Keep2C peut proposer différentes aides et formations dans les secteurs de la bureautique."
                    },
                    {
                        "text": "Keep2C est en partenariat avec une société combattant l'illectronisme( difficulté à utiliser les outils informatiques en raison d'un manque de connaissances à propos de leur fonctionnement)."
                    },
                ],
            },
        ],
    },
    {
        "id": "contact",
        "title": "Contact",
        "elements": [
            {
                "image": {
                    "file": "_contact.png",
                    "alt": "Nous contacter, Contact, E-mail.",
                    "url_link": "https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2993000",
                    "text_link": "Pixabay",
                    "url_link_author": "https://pixabay.com/fr/users/tumisu-148124/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2993000",
                    "text_link_author": "Tumisu",
                },
            },
            {
                "table": [
                    {
                        "key": "Entreprise",
                        "text": "Keep2C\nSociété par Actions Simplifiée",
                    },
                    {
                        "key": "Siège",
                        "text": "2 rue Rosalie Levasseur\n78700 Conflans-Sainte-Honorine",
                    },
                    {
                        "key": "Création",
                        "text": "07 février 2024",
                    },
                    {
                        "key": "SIREN",
                        "text": "984 923 805",
                    },
                    {
                        "key": "Président",
                        "text": "Frédéric CHARRON",
                    },
                    {
                        "key": "Site",
                        "url_link": "https://keep2c.com",
                        "text_link": "https://keep2c.com",
                    },
                    {
                        "key": "Mail",
                        "mailTo": "frederic.charron@keep2c.com",
                    },
                ],
            },
        ],
    },
];

export default sectionsContent;