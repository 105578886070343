import * as React from "react";

const LogoTxt = (props) => (
  <svg
    className="LogoTxt"
    id="Calque_1"
    data-name="Calque 1"
    viewBox="0 0 145.211 75.979"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs1">
      <style id="style1">
        {
          ".cls-2{fill:#222954;letter-spacing:-.13em}.cls-4{font-family:Arial-BoldMT,Arial;font-size:32.32px;font-weight:700}"
        }
      </style>
    </defs>
    <g id="Logo" transform="translate(-.002 .002)">
      <path
        d="M31.68 33.62 6.29 68.56c-1.12 1.54-.02 3.7 1.88 3.7h50.77c1.9 0 3-2.16 1.88-3.7L35.43 33.62a2.33 2.33 0 0 0-3.77 0z"
        id="path1"
        style={{
          fill: "#222954",
        }}
      />
      <path
        d="M16.93 16.06.1 73.13c-.64 2.17 2.01 3.79 3.65 2.22L78.29 3.81c1.63-1.57.13-4.29-2.07-3.74L18.51 14.55c-.76.19-1.36.77-1.58 1.52z"
        id="path2"
        style={{
          fill: "#0aaee1",
        }}
      />
      <text
        className="cls-4"
        id="text3-74"
        style={{
          fontWeight: 700,
          fontSize: "46.5526px",
          fontFamily: "Arial-BoldMT,Arial",
          strokeWidth: 1.44036,
        }}
        x={132.996}
        y={63.316}
        transform="scale(.88422 1.13094)"
      >
        <tspan
          className="cls-2"
          x={132.996}
          y={63.316}
          id="tspan2-6"
          style={{
            strokeWidth: 1.44036,
          }}
        >
          {"C"}
        </tspan>
      </text>
      <text
        className="cls-4"
        id="text3-7"
        style={{
          fontWeight: 700,
          fontSize: "53.6538px",
          fontFamily: "Arial-BoldMT,Arial",
          strokeWidth: 1.66008,
        }}
        x={23.005}
        y={71.396}
        transform="scale(.98965 1.01046)"
      >
        <tspan
          x={94.538}
          y={71.396}
          id="tspan3-5"
          style={{
            fill: "#00b4d8",
            letterSpacing: "-.13em",
            strokeWidth: 1.66008,
          }}
        >
          {"2"}
        </tspan>
      </text>
      <text className="cls-4" transform="translate(53.04 66.93)" id="text3">
        <tspan className="cls-2" x={0} y={0} id="tspan2">
          {"eep"}
        </tspan>
      </text>
    </g>
  </svg>
);
export default LogoTxt;
