import React from "react";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretUp, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch } from 'react-redux';
import { selectSizePositionIsSmall } from '../../Slices/sizeSmallSlice';
import { selectScrollPositionIsInHeader } from '../../Slices/scrollHeaderSlice';
import { selectIsVisibleBarSmall, toggleIsVisibleBarSmall, updateIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';
import { updateIsLegalsVisible } from '../../Slices/contentSlice';

const AdjustedIcon = () => {

    const sizePositionIsSmall = useSelector( selectSizePositionIsSmall );
    const scrollPositionIsInHeader = useSelector( selectScrollPositionIsInHeader );
    const isVisibleBarSmall = useSelector( selectIsVisibleBarSmall );
    const dispatch = useDispatch();

    const hideLegals = () => dispatch( updateIsLegalsVisible( [false ,false]  ) );

    let currentIcon;
    let clickedIcon;
    if ( sizePositionIsSmall && scrollPositionIsInHeader && isVisibleBarSmall ) {
        currentIcon = faXmark;
        clickedIcon = () => {
            dispatch( toggleIsVisibleBarSmall( ) );
        };
    } else if ( sizePositionIsSmall && !scrollPositionIsInHeader ) {
        currentIcon = faCaretUp;
        clickedIcon = () => {
            dispatch( updateIsVisibleBarSmall( [true ,false] ) );
        };
    } else {
        currentIcon = faBars;
        clickedIcon = () => {
            hideLegals();
            dispatch( updateIsVisibleBarSmall( [true, false]) );
        };
    };

    const clicked = () => {
        clickedIcon();
    }


    return (
        <Link activeClass="active" smooth spy to="header" >   
            <FontAwesomeIcon
                icon={ currentIcon }
                className="
                    m-0 h-12 w-10 bg-white bg-opacity-40 p-2 rounded-lg cursor-pointer
                    md:hidden"
                onClick={ clicked }
            />
        </Link>
    );
};

export default AdjustedIcon;