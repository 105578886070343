import React from "react";
import SectionCollection from './SectionCollection'
// import './PlaceHolder.css'

const PlaceHolder = () => {
    return (
        <div className="PlaceHolder">
            <SectionCollection />
        </div>
        );
    }
    
export default PlaceHolder;