import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  position: 0,
  limit: 400,
  scrollPositionIsInHeader: true,
};

const scrollHeaderSlice = createSlice({
  name: 'scrollHeader',
  initialState,
  reducers: {
    updateScrollPositionIsInHeader: ( state, action ) => {
      const [data , debug] = action.payload;
      state.position = data;
      state.scrollPositionIsInHeader = state.position < state.limit ? true : false;
      debug && console.log(
        'updateScrollPositionIsInHeader :',
        state.position, ' / ', state.limit, ' => ', state.scrollPositionIsInHeader );
    },
  },
});

export const { updateScrollPositionIsInHeader } = scrollHeaderSlice.actions;

export const selectPosition = (state) => state.scrollHeader.position;
export const selectScrollPositionIsInHeader = (state) => state.scrollHeader.scrollPositionIsInHeader;

export default scrollHeaderSlice;
