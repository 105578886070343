import React, { Fragment } from "react";
import UrlLink from '../Common/UrlLink';

const List = props => {

    return (
        <ul>
            {props.list && props.list.map((elt, index) => (
                <Fragment  key={`li-${index}`}>
                    { elt.bold && <li className="text-justify m-5 text-federal-blue font-semibold text-xl">{elt.bold}</li> }
                    { elt.text && <li className="text-justify m-5 text-federal-blue">{elt.text}</li> }
                    { elt.url_link && <UrlLink href={`${elt.url_link}`} >{elt.text_link}</UrlLink>}
                </Fragment>
            ))}
        </ul>
    );
}

export default List;