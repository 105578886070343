import React from "react";

import NavBarSmall from "./NavBarSmall";
import NavBarMedium from "./NavBarMedium";

const NavBar = () => {


    return (
        <>
            <NavBarSmall />
            <NavBarMedium />
        </>
    );
}

export default NavBar;