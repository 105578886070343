import React from 'react';


const UrlLink = props => {

  return (
    <a href={props.href} className='underline text-honolulu-blue select-none' target='_blank' rel='noopener noreferrer'>{ props.children }</a>
  )
}

export default UrlLink;