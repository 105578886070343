import React from "react";

const Title1 = props => {

    return (
        <h1 className="text-non-photo-blue text-shadow-md shadow-federal-blue text-center text-4xl m-32
        md:text-6xl md:m-44"
        >
            {props.children}
        </h1>           
    );
}

export default Title1;