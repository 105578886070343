const legalsContent = [
    {
        "id": "redaction",
        "title": "Rédaction et publication du site",
        "elements": [
            {
                "table": [
                    {
                        "key": "Entreprise",
                        "text": "Keep2C\nSociété par Actions Simplifiée",
                    },
                    {
                        "key": "Siège",
                        "text": "2 rue Rosalie Levasseur\n78700 CONFLANS-SAINTE-HONORINE",
                    },
                    {
                        "key": "R.C.S.",
                        "text": "Versailles 984 923 805",
                    },
                ],
            },
        ],
    },
    {
        "id": "site",
        "title": "Création graphique et réalisation du site",
        "elements": [
            {
                "image": {
                    "file": "_contact.png",
                    "alt": "Nous contacter, Contact, E-mail.",
                    "url_link": "https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2993000",
                    "text_link": "Pixabay",
                    "url_link_author": "https://pixabay.com/fr/users/tumisu-148124/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2993000",
                    "text_link_author": "Tumisu",
                },
            },
            {
                "table": [
                    {
                        "bold": "L'ensemble du site a été réalisé par Keep2C",
                    },
                    {
                        "text": "Vous trouverez systématiquement les liens demandés par l'utilisation des images libres de droits.",
                    },
                    {
                        "url_link": "https://keep2c.com/",
                        "text_link": "Réalisation du site: Keep2C",
                    },
                ],
            },



        ],
    },
    {
        "id": "acces",
        "title": "Accès et rectifications des informations",
        "elements": [
            {
                "list": [
                    {
                        "bold": "Conformément aux articles 26 et 27 de la Loi française 78-17 du 6 janvier 1978:"
                    },
                    {
                        "text": "Les utilisateurs peuvent exercer leur droit d'accès et de rectification des informations saisies à l'adresse ci-dessus."
                    },
                ],
            },
        ],
    },
    {
        "id": "cookies",
        "title": "Cookies",
        "elements": [
            {
                "list": [
                    {
                        "bold": "Actuellement ce site n'utilise que très peu de cookies, cependant voici les informations les concernant :"
                    },
                    {
                        "text": "Les cookies permettent de mesurer l’audience d'un site et d’offrir des fonctionnalités relatives aux médias sociaux.",
                    },
                    {
                        "text": "Nous sommes amenés à partager ses informations sur l'utilisation de notre site avec nos partenaires médias sociaux, de publicité et d'analyse, qui peuvent combiner celles-ci avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services."
                    },
                    {
                        "bold": "Vous pouvez à tout moment désactiver les cookies sur notre site, à partir des paramètres de votre navigateur web grâce aux liens ci dessous. Vous pouvez également changer vos choix en terme de cookies :"
                    },
                    {
                        "text": "Il est possible de désactiver tout ou partie des cookies selon les procédés décrits par les éditeurs de navigateurs. Cependant en cas de refus global des cookies et notamment des cookies « techniques », certaines fonctionnalités du site ne seront plus accessibles et nous ne pourrons en aucun cas être tenu pour responsable."
                    },
                    {
                        "text": "La gestion diffère d’un navigateur à l’autre et d’une version à l’autre, il faut vous référer à la documentation de votre navigateur pour effectuer ces réglages."
                    },
                ],
            },
        ],
    },
    {
        "id": "liens",
        "title": "Liens actuels pour les principaux navigateurs",
        "elements": [
            {
                "table": [
                    {
                        "text_link": "Internet Explorer",
                        "url_link": "https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies",
    
                    },
                    {
                        "text_link": "Google Chrome",
                        "url_link": "https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en",
                    },
                    {
                        "text_link": "Mozilla Firefox",
                        "url_link": "https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies",
                    },
                    {
                        "text_link": "Opéra",
                        "url_link": "https://help.opera.com/en/latest/web-preferences/#cookies",
                    },                 
                    {
                        "text_link": "IOS",
                        "url_link": "https://support.apple.com/fr-fr/HT201265",
                    },
                    {
                        "text_link": "Android",
                        "url_link": "https://support.google.com/accounts/answer/32050?hl=fr&co=GENIE.Platform%3DAndroid",
                    },
                ],
            },
        ],
    },
    {
        "id": "collecte",
        "title": "Accès et rectifications des informations",
        "elements": [
            {
                "list": [
                    {
                        "bold": "Actuellement, il n'existe pas de données collectées par le biais du site de Keep2C"
                    },
                    {
                        "text": "Cependant, lorsque le site permettra la moindre collecte, voici comment celles-ci seront traitées:"
                    },
                    {
                        "text": "Les données collectées sur ce site seront celles contenues dans le formulaire de contact et/ou d’inscription à la newsletter."
                    },
                    {   
                        "text": "Elles permettront de répondre à vos demandes et de vous proposer des offres pesonnalisées. Ces données ne seront transmises à aucun tiers et seront conservées un an."
                    },
                    {
                        "bold": "Conformément à la loi Informatique et Libertés n 78-17 du 6 janvier 1978 modifiée et le règlement 2016/679 du 27 avril 2016 (RGPD):"
                    },
                    {
                        "text": "Vous disposez d'un droit d'accès, de rectification, d'opposition et de suppression aux données vous concernant en écrivant à Keep2C - 2 rue Rosalie Levasseur - 78700 Conflans-Sainte-Honorine"
                    },
                ],
            },
        ],
    },
];
export default legalsContent;