import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';

import App from './Containers/App/App';
import store from './Store/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <StrictMode>
    <Provider store={store} >
      <App />
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
