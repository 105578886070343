import { createSlice } from '@reduxjs/toolkit';
import sectionsContent from '../Data/sectionsContent';
import legalsContent from '../Data/legalsContent';

const sections = sectionsContent;
const legals = legalsContent;

const initialState = {
  contentText: sections,
  isLegalsVisible: false,
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    toggleIsLegalsVisible: ( state, action ) => {
      const [debug] = action.payload ?? [ false ];
      state.isLegalsVisible = !state.isLegalsVisible;
      state.contentText = state.isLegalsVisible ? legals : sections;
      debug && console.log('toggleIsLegalsVisible');
    },
    updateIsLegalsVisible: ( state, action) => {
      const [isVisible, debug] = action.payload;
      state.isLegalsVisible = isVisible;
      state.contentText = state.isLegalsVisible ? legals : sections;
      debug && console.log('updateIsLegalsVisible => ', state.isLegalsVisible );
    },
  },
});

export const { toggleIsLegalsVisible, updateIsLegalsVisible } = contentSlice.actions;

export const selectIsLegalsVisible = (state) => state.content.isLegalsVisible;
export const selectContentText = (state) => state.content.contentText;

export default contentSlice;