import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { selectIsLegalsVisible } from "../../Slices/contentSlice";
import Title2 from "../Common/Title2";
import List from './List';
import ImgA from "../Common/ImgA";
import Table from "../Common/Table";

const SectionElement = props => {

    const isLegalsVisible = useSelector( selectIsLegalsVisible );

    const defaultBgColor = isLegalsVisible ? 
        "bg-gradient-to-r from-federal-blue via-light-cyan to-federal-blue" :
        "bg-gradient-to-r from-light-cyan to-vivid-sky-blue";
    
    return (

        <section 
            id={props.content.id}
            className={`${props.content.bg_img}
                ${defaultBgColor}
                m-0
            `}
        >
            <div className=" mt-16" >
                <Title2 title={props.content.title} />
            </div>
            <div className="flex flex-col w-full p-10
                            md:flex-row md:flex-wrap md:justify-center"
            >
                { props.content.elements && props.content.elements.map((elt, index) => (
                    <Fragment key={`list_or_image-${index}`}>

                        { elt.list &&
                            <div key={`list-${index}`}
                                className={`p-2 m-2 shadow-xl
                                            ${isLegalsVisible && 'bg-light-cyan'}
                                            md:border md:border-2 md:border-federal-blue md:rounded-ss-[64px] md:rounded-ee-[64px]
                                            md:w-3/6`}
                            >
                                <List list={elt.list} />
                            </div>
                        }

                        { elt.image &&
                            <div key={`image-${index}`}
                                className={`m-4 p-6
                                md:w-2/6 md:p-2`}
                            >
                                <ImgA 
                                    image={elt.image.file} 
                                    title={elt.image.alt} 
                                    url_link={elt.image.url_link} text_link={elt.image.text_link}
                                    url_link_author={elt.image.url_link_author} text_link_author={elt.image.text_link_author}
                                />
                            </div>
                        }

                        { elt.table &&
                            <div key={`table-${index}`}
                                className={`m-2 p-2 shadow-xl
                                            ${isLegalsVisible && 'bg-light-cyan'}
                                            md:border md:border-2 md:border-federal-blue md:rounded-ss-[64px] md:rounded-ee-[64px] md:p-6
                                            md:w-3/6`}
                            >
                                <Table table={elt.table} />
                            </div>
                        }

                    </Fragment>
                ))}
            </div>


        </section>
    );
}

export default SectionElement;