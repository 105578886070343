import React from "react";
import { useSelector } from 'react-redux';
import { selectContentText } from '../../Slices/contentSlice';

import SectionElement from "./SectionElement";

const SectionCollection = () => {

    const contentText = useSelector( selectContentText );

    return (
        <section className="flex flex-col">
            {contentText && contentText.map( ( elt, index ) => (
                <SectionElement content={elt} key={`sectionElement-${index}`} />
            ))}
        </section>
    );
};

export default SectionCollection;