import React from "react";

import LinkList from "./LinkList";

const FooterCollection = () => {

    return (

        <div className="flex flex-row items-center justify-center h-full">
            <LinkList />
        </div>

    );
}

export default FooterCollection;