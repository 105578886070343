import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLegalsVisible } from '../../Slices/contentSlice';
import { updateScrollPositionIsInHeader } from '../../Slices/scrollHeaderSlice';
import { updateSizePositionIsSmall } from '../../Slices/sizeSmallSlice';
import { updateIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';
import { selectSectionsTags, updateActiveSectionTag, updateSectionsTags } from '../../Slices/sectionsSlice';

import CookieConsent from "react-cookie-consent";

import Header from '../../Components/Header/Header';
import PlaceHolder from '../../Components/PlaceHolder/PlaceHolder';
import Footer from '../../Components/Footer/Footer';

const App = () => {

  const dispatch = useDispatch();
  const sectionsTags = useSelector(selectSectionsTags);
  const isLegalsVisible = useSelector(selectIsLegalsVisible);

  // STATES

  // EFFECTS
  useEffect(() => {

    const getSection = (name) => {
      const y = document.getElementById(name).getBoundingClientRect().top;
      return y;
    };

    const getIndexOfMin = (array2D) => {
      const newArray = array2D.map(elt => Math.abs(elt[1]) ) ;
      return (newArray.indexOf(Math.min(...newArray)));
    };

    const getSections = () => {

      const newSectionsTags = sectionsTags.map(elt => [elt[0], getSection(elt[0])]);

      const activeSection = (newSectionsTags.find(elt => elt[1] >= -50 && elt[1] <= 200)?.[0])
        || newSectionsTags[getIndexOfMin(newSectionsTags)][0];
      return [activeSection, newSectionsTags];
    }

    const dispatchSections = () => {
      const [activeSection, newSectionsTags] = getSections();
      dispatch(updateActiveSectionTag([activeSection, false]));
      dispatch(updateSectionsTags([newSectionsTags, false]));
    }

    const handleResize = () => {
      const position = window.innerWidth;
      dispatch(updateSizePositionIsSmall([position, false]));
      dispatch(updateIsVisibleBarSmall([false, false]));
      if (!isLegalsVisible) dispatchSections();
    };

    const handleScroll = () => {
      const position = window.scrollY;
      dispatch(updateScrollPositionIsInHeader([position, false]));
      if (!isLegalsVisible) dispatchSections();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, { passive: true });
    // console.log("APPS - didMount");
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      // console.log("APPS - unMount");
    };
  }, [dispatch,sectionsTags,isLegalsVisible]);

  // FUNCTIONS

  // JSX
  return (

    <div className="m-0 p-0 bg-light-cyan font-sans font-normal">
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris!"
        cookieName="cookieForKeep2CSite"
        style={{ background: "#303030" }}
        buttonStyle={{ background:"#caf0f8", color: "#03045e", fontSize:"13px"}}
        expires={150}
      >
        <span className='text-light-cyan text-base'>
        Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.

        </span>
      </CookieConsent>
      <Header />
      <PlaceHolder />
      <Footer />
    </div>

  );
}

export default App;