import * as React from "react";
const Logo = (props) => (
  <svg
    className="logo"
    data-name="Calque 1"
    viewBox="0 0 78.976 75.979"
    width={78.976}
    height={75.979}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.68 33.62 6.29 68.56c-1.12 1.54-.02 3.7 1.88 3.7h50.77c1.9 0 3-2.16 1.88-3.7L35.43 33.62a2.33 2.33 0 0 0-3.77 0z"
      style={{
        fill: "#222954",
      }}
      transform="translate(-.002 .002)"
    />
    <path
      d="M16.93 16.06.1 73.13c-.64 2.17 2.01 3.79 3.65 2.22L78.29 3.81c1.63-1.57.13-4.29-2.07-3.74L18.51 14.55c-.76.19-1.36.77-1.58 1.52z"
      style={{
        fill: "#0aaee1",
      }}
      transform="translate(-.002 .002)"
    />
  </svg>
);
export default Logo;