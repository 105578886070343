import { createSlice } from '@reduxjs/toolkit';

const sectionsTags = [
  ['header', 0],
  ['presentation', 0],
  ['missions', 0],
  ['valeurs', 0],
  ['fondateur', 0],
  ['produits', 0],
  ['services', 0],
  ['contact', 0],
  ['footer', 0]
];

const initialState = {
  sectionsTags,
  activeSectionTag: 'header',
}

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    updateSectionsTags: (state, action) => {
      const [sectionsTags, debug] = action.payload ?? [false];
      state.sectionsTags = sectionsTags;
      debug && console.log('updateSectionsTags: ',sectionsTags);
    },
    updateActiveSectionTag: (state, action) => {
      const [activeSectionTag, debug] = action.payload;
      state.activeSectionTag = activeSectionTag;
      debug && console.log('updateActiveSectionTag: ', state.activeSectionTag,);
    },
  },
});

export const { updateSectionsTags, updateActiveSectionTag } = sectionsSlice.actions;

export const selectSectionsTags = (state) => state.sections.sectionsTags;
export const selectActiveSectionTag = (state) => state.sections.activeSectionTag;
export default sectionsSlice;