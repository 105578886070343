import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLegalsVisible, updateIsLegalsVisible } from '../../Slices/contentSlice';
import { selectActiveNavAction, updateActiveNavAction } from '../../Slices/activeNavActionSlice';
import { selectActiveSectionTag } from '../../Slices/sectionsSlice';
import { scroller } from "react-scroll";

const NavAction = props => {

    const dispatch = useDispatch();

    const isLegalsVisible = useSelector( selectIsLegalsVisible );
    const activeNavAction = useSelector( selectActiveNavAction );
    const activeSectionTag = useSelector( selectActiveSectionTag );

    useEffect( () => {
        if ( !isLegalsVisible && activeNavAction === props.to ) {
            scroller.scrollTo(props.to, { smooth: 'easeInOutQuart' });
            dispatch(updateActiveNavAction(['header',false]));
        };
    },[isLegalsVisible, activeNavAction, dispatch, props.to]);


    const clicked = () => {
        dispatch( updateActiveNavAction( [props.to] ) );
        dispatch( updateIsLegalsVisible ( [false] ) );
    };
    
    return (
        <li className="mx-4 my-1 text-light-cyan text-[15px] text-center md:m-1">
            <span
                onClick={ clicked }
                className={`${ (props.to === activeSectionTag) && !isLegalsVisible ? "active ":""}
                    flex flex-col bg-anthracite-grey border-2 border-federal-blue rounded-lg text-white px-4 py-1 cursor-pointer transition select-none
                    hover:border-light-cyan
                    md:px-1 md:py-0 
                    `}>
                {props.label}
            </span>
        </li>
    );
};

export default NavAction;




// import React from "react";

// import { useDispatch } from 'react-redux';
// import { updateIsLegalsVisible } from '../../Slices/contentSlice';
// import { updateIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';

// import { Link } from "react-scroll";

// const NavAction = props => {

//     const dispatch = useDispatch();

//     const clicked = () => {
//         dispatch( updateIsLegalsVisible ( [false ,true]  ) )

//     };
    
//     return (
//         <li 
//         className="
//         mx-4 my-1 text-light-cyan text-[15px] text-center
//         md:m-1
//         ">
//             <Link
//                 activeClass="active"
//                 smooth spy to={props.to}
//                 isDynamic={ true }
//                 className="
//                 flex flex-col
//                 bg-anthracite-grey border-2 border-federal-blue rounded-lg text-white
//                 px-4 py-1
//                 md:px-1 md:py-0
//                 hover:border-light-cyan cursor-pointer transition
//             ">
//                 <span onClick={ clicked } >
//                     {props.label}
//                 </span>
//             </Link>
//         </li>
//     );
// };

// export default NavAction;