import React from "react";
import FooterCollection from './FooterCollection'

const Footer = () => {
    return (
        <footer 
            id="footer"
            className="
                p-4 h-auto bg-gradient-to-r from-anthracite-grey to-federal-blue
                md:h-auto
        ">
            <FooterCollection />
        </footer>
    );
}

export default Footer;