import React from "react";
import { useSelector } from 'react-redux';
import { selectSizePositionIsSmall } from '../../Slices/sizeSmallSlice';
import { selectIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';

import NavAction from './NavAction';

const NavBarSmall = () => {

    const sizeSmall = useSelector(selectSizePositionIsSmall);
    const isVisibleBarSmall = useSelector( selectIsVisibleBarSmall );
    const display = sizeSmall && isVisibleBarSmall;

    return (
        <>
            { display &&
                <ul
                    className={` 
                        flex flex-col items-center mx-2 mt-16 mb-2
                        md:hidden
                    `}
                >
                    <NavAction label="Présentation" to="presentation" className="active" />
                    <NavAction label="Missions" to="missions" />
                    <NavAction label="Valeurs" to="valeurs" />
                    <NavAction label="Fondateur" to="fondateur" />
                    <NavAction label="Produits" to="produits" />
                    <NavAction label="Services" to="services" />
                    <NavAction label="Contact" to="contact" />
                </ul>
            }
        </>
    );
};

export default NavBarSmall;