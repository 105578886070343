import React from "react";

const Title2 = props => {

    return (
        <>
            {props.title &&
                <h2 className="text-federal-blue font-semibold text-4xl text-center text-shadow shadow-gray-500
                            after:block after:content-['•••']
                            "
                >
                    {props.title}
                </h2>
            }
        </>
    );
}

export default Title2;