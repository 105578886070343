import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLegalsVisible, toggleIsLegalsVisible } from '../../Slices/contentSlice';
import { updateIsVisibleBarSmall } from '../../Slices/navBarSmallSlice';

import Logo from '../../Components/Common/Logo';
import LinkAction from './LinkAction';

const LinkList = () => {

    const isLegalsVisible = useSelector( selectIsLegalsVisible );
    const dispatch = useDispatch();

    const clicked = () => {
        dispatch( updateIsVisibleBarSmall( [false, false]) );
        dispatch( toggleIsLegalsVisible( [false] ) )
    }


    const altMentionsLegales = isLegalsVisible ? "Retour au site" : "Mentions légales";
    return (
        <ul
            className="
                list-none flex items-center justify-between
                gap-4 p-3 w-full m-8
        ">
            <div className="text-left w-1/3">
                <li className="text-xl text-light-cyan">
                    {`Copyright© ${new Date().getFullYear()} Keep2C`}
                </li>     
            </div>
                
            <div className="text-center w-1/3">
                <LinkAction
                    label={ altMentionsLegales }
                    toggleLegalPlaceHolder={ clicked }
                />
            </div>
            <div className="w-1/3
                flex flex-row-reverse
            ">
                <Logo className="
                    h-20 w-20 p-1
                    bg-light-cyan rounded-ss-[24px] rounded-ee-[24px]
                    "/>
                </div>
        </ul>
    );
}

export default LinkList;