import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeNavAction: "header",
};

const activeNavActionSlice = createSlice({
  name: 'activeNavAction',
  initialState,
  reducers: {
    updateActiveNavAction: ( state, action) => {
      const [ activeNavAction, debug] = action.payload;
      state.activeNavAction = activeNavAction;
      debug && console.log('updateActiveNavAction => ', state.activeNavAction );
    },
  },
});

export const { updateActiveNavAction } = activeNavActionSlice.actions;

export const selectActiveNavAction = (state) => state.activeNavAction.activeNavAction;

export default activeNavActionSlice;
