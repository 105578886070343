import React from "react";
import { Link } from "react-scroll";
import LogoTxt from '../Common/LogoTxt'
import NavBar from "./NavBar";
import AdjustedIcon from "./AdjustedIcon";


const HeaderCollection = () => {
    
    return (
        <nav className="
            flex flex-col items-center justify-between static
            md:flex-row md:justify-between md:items-start md:fixed md:inset-x-4
        ">
            <div className="
                flex flex-row justify-between fixed w-11/12
                md:w-9/12 md:static 
            ">
                <Link activeClass="active" smooth spy to="header" >
                    <LogoTxt className="w-28 p-1 cursor-pointer bg-white bg-opacity-40 p-2 rounded-lg hover:w-32"/>
                </Link >
                <AdjustedIcon />
            </div>
            <NavBar />
        </nav>
    );
};

export default HeaderCollection;